import React from "react"
import ForInvestors from "../../components/forInvestors/forInvestors"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

const ForInvestorsPage = () => (
  <>
    <Helmet>
      <title>WILLBUD | Dla Inwestorów</title>
    </Helmet>
    <Layout>
      <ForInvestors />
    </Layout>
  </>
)

export default ForInvestorsPage
