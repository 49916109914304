import React, { useState } from "react"
import {
  AccordionStyled,
  ContactStyled,
  SingleAccordContact,
} from "../contact/contactStyle"
import { Accordion, Card, Col, Container, Row } from "react-bootstrap"
import icon1 from "../../images/info.png"
import { CardBodyStyled } from "../footer/footerStyle"
import { EmailButton, PhoneButton } from "../shared/button/button"
import icon3 from "../../images/memo.png"
import ContactForm from "../shared/contactForm/contactForm"
import { injectIntl } from "gatsby-plugin-intl"

const ForInvestors = ({ intl }) => {
  const [activeTab, setActiveTab] = useState(false)
  return (
    <ContactStyled>
      <Container>
        <Row>
          <Col xs={12} lg={6} className={"contact-wrapper"}>
            <div className="heading">
              <h1>{intl.formatMessage({ id: "investors.title" })}</h1>
              <p className={"subtitle-head"}>
                {intl.formatMessage({ id: "investors.content.bold" })}
              </p>
              <p> {intl.formatMessage({ id: "investors.content" })}</p>
            </div>
            <AccordionStyled>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Header}
                      variant="link"
                      eventKey="0"
                    >
                      <div className="flex">
                        <SingleAccordContact
                          onClick={() =>
                            setActiveTab(activeTab === 1 ? false : 1)
                          }
                          active={activeTab === 1}
                        >
                          <div className="icon">
                            <img src={icon1} alt="" />
                          </div>
                          <span>
                            {intl.formatMessage({ id: "headquater.title" })}
                          </span>
                        </SingleAccordContact>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <CardBodyStyled>
                      <Card.Body>
                        <div className="subtitle">Willbud</div>
                        <p>
                          {intl.formatMessage({
                            id: "investors.headquater.adress.street",
                          })}
                        </p>
                        <p>
                          {intl.formatMessage({
                            id: "investors.headquater.adress.post",
                          })}
                        </p>
                        <p>
                          {intl.formatMessage({
                            id: "investors.headquater.adress.hours",
                          })}
                        </p>
                        <div className="buttons">
                          <PhoneButton />
                          <EmailButton innerText={"inwestor@willbud.pl"} />
                        </div>
                      </Card.Body>
                    </CardBodyStyled>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Card.Header}
                      variant="link"
                      eventKey="2"
                    >
                      <div className="flex">
                        <SingleAccordContact
                          onClick={() =>
                            setActiveTab(activeTab === 3 ? false : 3)
                          }
                          active={activeTab === 3}
                        >
                          <div className="icon">
                            <img src={icon3} alt="" />
                          </div>
                          <span>
                            {intl.formatMessage({ id: "company.title" })}
                          </span>
                        </SingleAccordContact>
                      </div>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <CardBodyStyled>
                      <Card.Body>
                        <div className="subtitle">WILLBUD M.WILLA S.K.A</div>
                        <p>
                          {intl.formatMessage({ id: "company.adress.street" })}
                        </p>
                        <p>
                          {intl.formatMessage({ id: "company.adress.post" })}
                        </p>
                        <p>
                          {intl.formatMessage({ id: "company.adress.krs" })}
                        </p>
                        <p>
                          {intl.formatMessage({ id: "company.adress.nip" })}
                        </p>
                        <p>
                          {intl.formatMessage({ id: "company.adress.regon" })}
                        </p>
                      </Card.Body>
                    </CardBodyStyled>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </AccordionStyled>
          </Col>
          <Col
            lg={6}
            className={
              "d-none d-lg-block position-static form-container-parent"
            }
          >
            <div className="bg-form"></div>
            <ContactForm investorsView={true} />
          </Col>
        </Row>
      </Container>
      <div className="bg-gray d-block d-lg-none">
        <Container>
          <ContactForm investorsView={true} />
        </Container>
      </div>
      <div className="buttons-call d-block d-lg-none">
        <Container>
          <PhoneButton innerText={"inwestor@willbud.pl"} noIcon={true} />
          <EmailButton noIcon={true} />
        </Container>
      </div>
    </ContactStyled>
  )
}

export default injectIntl(ForInvestors)
